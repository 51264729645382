import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Quote from '../Quote'
import Button from '../../Button'
import Link from '../../Link'
import contentfulUrlForLink from 'lib/contentful-url-for-link'

// eslint-disable-next-line react/prop-types
const LinkWrapper = ({ link, children }) => {
  return <Link to={contentfulUrlForLink(link)}>{children}</Link>
}

// eslint-disable-next-line react/prop-types
const FragmentWrapper = ({ children }) => {
  return <Fragment>{children}</Fragment>
}

// we're missing a reference component, why is that?
const Reference = ({ image, title, quote, description, date, link }) => {
  if (!quote || !quote.quote) {
    console.error('No quote given', quote)
    return null
  }

  const content = (
    <Fragment>
      <div className="article__card--img-full">
        <img src={image.url} alt={image.description} />
      </div>
      <div className="article__card--content">
        <h4 className="article__card--title">{title}</h4>
        <q className="article__card--quote fs--italic">{quote.quote}</q>
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="article__card--description"
        />
        <div className="article__date">{date}</div>
      </div>
      {link && link.label ? (
        <div className="article__card--footer">
          <Button link={link} className="btn btn--primary">
            {link.label}
          </Button>
        </div>
      ) : null}
    </Fragment>
  )

  const wrappedContent = link ? (
    <LinkWrapper link={link}>{content}</LinkWrapper>
  ) : (
    <FragmentWrapper>{content}</FragmentWrapper>
  )

  return <div className="article__card hover--shadow">{wrappedContent}</div>
}

Reference.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  quote: PropTypes.shape(Quote.propTypes),
  date: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  link: linkPropType,
}

export default Reference
