import React from 'react'
import PropTypes from 'prop-types'

const Quote = props => {
  return (
    <div className="hm-quote--wrapper">
      <div className="hm-quote">
        <div className="hm-quote--inner">
          <q>{props.quote}</q>
          <span className="hm-quote--author">{props.author}</span>
        </div>
      </div>
    </div>
  )
}

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
}

export default Quote
