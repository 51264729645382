import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Quote from '../Quote'
import Button from '../../Button'
import Markdown from '../../Markdown'
import contentfulImage from '../../../lib/contentful-image'
import Reference from '../Reference'

const OldReferences = props => {
  const backgroundImage = contentfulImage(props.backgroundImage.url, {
    width: 1240,
    format: 'jpg',
    quality: 80,
  })

  // only show one quote for now (slider to be implemented later)
  const quotes = props.quotes.length > 0 ? [props.quotes[0]] : []

  return (
    <div className="section section--padded-big">
      <div
        className="section__inner tc"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          color: 'white',
          padding: '2rem 1rem',
        }}
      >
        <h2 className="headline">{props.title}</h2>
        <Markdown className="column--measure-medium center">
          {props.description}
        </Markdown>
        {quotes.map((quote, i) => {
          return <Quote key={`${props.id}-${quote.id}-${i}`} {...quote} />
        })}
        {props.link ? (
          <p>
            <Button className="btn--primary" link={props.link}>
              {props.link.label}
            </Button>
          </p>
        ) : null}
      </div>
    </div>
  )
}

const References = props => {
  if (!props.references || props.references.length === 0) {
    // allow both new and old reference format for now, until all old entries are removed from the site
    return <OldReferences {...props} />
  }
  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <h2 className="headline tc">{props.title}</h2>
        <div className="article__cards">
          {props.references.map((reference, i) => {
            return <Reference key={`reference-article-${i}`} {...reference} />
          })}
        </div>
      </div>
    </div>
  )
}

OldReferences.propTypes = References.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  quotes: PropTypes.arrayOf(PropTypes.shape(Quote.propTypes)),
  link: linkPropType,
  backgroundImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  references: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      quote: Quote.propTypes,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
      link: linkPropType,
    })
  ),
}

export default References
